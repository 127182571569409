<!-- 应用列表 -->
<template>
  <div class="h100 pl10">
    <el-row v-if="showAppList" class="h100">
      <el-col :span="18" class="h100">
        <common-layout>
          <template #header>
            <el-form inline>
              <el-form-item>
                <el-input placeholder="请输入标签名称" v-model="selectLabel" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="query">查询</el-button>
                <el-button type="primary" plain @click="handleEvent('add')">新增</el-button>
              </el-form-item>
            </el-form>
          </template>
          <template #default>
            <el-table ref="table" :data="tableData" border highlight-current-row height="100%">
              <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" min-width="180px" />
              <el-table-column label="操作" align="center" fixed="right" width="150px">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEvent('see', scope.row)">查看</el-button>
                  <el-button type="text" @click="handleEvent('edit', scope.row)">编辑</el-button>
                  <el-button type="text" @click="handleEvent('delete', scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </common-layout>
      </el-col>
      <el-col :span="6" class="h100">
        <w-form-box :operType="opertype" :formData="curRow" @getData="getData" style="width: 300px" class="ml10 pl10 border-left-line h100 overflow-auto" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import WFormBox from '../common/formBox'

import Api from '@/api/devops/common'
export default {
  components: { WFormBox },

  props: {},
  data() {
    return {
      showAppList: true,
      tableData: [{}],
      tableHeader: [
        { code: 'label', name: '标签名称' },
        { code: 'color', name: '标签颜色' },
        {
          code: 'createdBy',
          name: '创建账号',
        },
        { code: 'createTime', name: '创建时间' },
        { code: 'updateBy', name: '更新账号' },
        { code: 'updateTime', name: '更新时间' },
      ],
      opertype: 'add',
      curRow: {},
      selectLabel: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    query() {
      this.getData()
    },
    async getData() {
      let params = {
        label: this.selectLabel,
      }
      let res = await Api.getLabel(params)
      if (res.data) {
        this.tableData = res.data
      }
    },
    handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.opertype = type
          break
        case 'see':
          this.opertype = type
          this.curRow = { ...row }
          break
        case 'edit':
          this.opertype = type
          this.curRow = { ...row }
          break
        case 'delete':
          this.deleteData(row)
          break
      }
    },
    deleteData(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let res = await Api.deLabel(row.id)
        this.$msg.success('删除成功')
        this.getData()
      })
    },
  },
}
</script>
