<template>
  <div>
    <div class="common-header">{{ titleData[operType] }}</div>
    <el-form label-width="80px" ref="formData" :model="formData" :rules="rules">
      <label v-if="operType !== 'see'">
        <el-form-item label="标签名称:" prop="label">
          <el-input v-model="formData.label" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="标签描述:" >
          <el-input v-model="formData.description" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="标签颜色:" prop="color">
          <el-color-picker v-model="formData.color"></el-color-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </label>
      <template v-if="operType === 'see'">
        <el-form-item label="标签名称:">
          <span class="font12">{{ formData.label }} </span>
        </el-form-item>

        <el-form-item label="标签描述:">
          <span class="font12">{{ formData.description }} </span>
        </el-form-item>

        <el-form-item label="创建账号:">
          <span class="font12">{{ formData.createdBy }} </span>
        </el-form-item>

        <el-form-item label="创建时间:">
          <span class="font12">{{ formData.createTime }} </span>
        </el-form-item>

        <el-form-item label="更新账号:">
          <span class="font12">{{ formData.updateBy }} </span>
        </el-form-item>
        <el-form-item label="更新时间:">
          <span class="font12">{{ formData.updateTime }} </span>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import Api from "@/api/devops/common";
export default {
  props: {
    formData: { default: () => {}, type: Object },
    operType: { type: String, default: "add" },
  },
  data() {
    return {
      titleData: {
        add: "新增标签",
        edit: "修改标签",
        see: "查看详情",
      },
      rules: {
        label: [{ required: true, message: "请输入标签名称", trigger: "blur" }],
      },
    };
  },

  methods: {
    onSubmit() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },
    async fetch() {
      let params = {
        ...this.formData,
      };
      const fn = this.formData.id ? Api.updateLabel : Api.addLabel;
      let res = await fn(params);
      this.formData.label = "";
      this.$msg.success("操作成功");
      this.$emit("getData");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
