import http from '@/utils/axios'

const DevOpsApi = {
  //前端框架
  getFront: (data) => http.get('eds/front/template/query/page', data),
  deFront: (data) => http.delete('eds/front/template/delete/' + data),
  addFront: (data) => http.post('eds/front/template/add', data),
  updateFront: (data) => http.put('eds/front/template/update', data),
  getFrontAll: (data) => http.get('eds/front/template/query/all', data),
  getDataList: (data) => http.get(`/eds/system/dictionary/data/list`, data),
}
export default DevOpsApi
