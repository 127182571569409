<template>
  <div class="h100">

    <div class="h100 ">
      <common-layout>
        <template #header>
          <el-form inline>
            <el-form-item label="模板名称">
              <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
            </el-form-item>
            <el-form-item label="模板编码">
              <el-input placeholder="请输入" v-model="form.code" clearable></el-input>
            </el-form-item>
            <el-form-item label="模板版本">
              <el-input placeholder="请输入" v-model="form.version" clearable></el-input>
            </el-form-item>
            <el-form-item label="标签">
              <el-select v-model="form.label" clearable>
                <el-option v-for="(item, index) in labelList" :key="index" :value="item.label" :label="item.label"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="query">查询</el-button>
              <el-button type="primary" plain @click="handleEvent('add')">新增</el-button>
              <el-button type="primary" plain @click="lableDrawer = true">标签管理</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref=" table" :data="tableData" v-loading="tableLoading" border highlight-current-row height="100%">
            <el-table-column label="序号" type="index" width="60" align="center" />
            <el-table-column prop="name" label="模板名称" align="center" />
            <el-table-column prop="code" label="模板编码" align="center" />
            <el-table-column prop="version" label="模板版本" align="center" />
            <el-table-column prop="label" label="标签" align="center" />
            <el-table-column prop="createdBy" label="创建账号" align="center" />
            <el-table-column prop="createTime" label="创建时间" align="center" />
            <el-table-column prop="updateBy" label="修改账号" align="center" />
            <el-table-column prop="updateTime" label="修改时间" align="center" />
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleEvent('edit', scope.row)">
                  编辑
                </el-button>
                <el-button type="text" @click="handleEvent('delete', scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        </template>
      </common-layout>
    </div>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" size="80%" direction="rtl" @close="getData()">
      <lableMgt></lableMgt>
    </el-drawer>

    <add-modal :show="addDrawer" :operType="opertype" :formData="rowData" :labelList="labelList" @getData="getData" @close="addModalClose">
    </add-modal>
  </div>
</template>
<script>
import addModal from "./addModal";
import lableMgt from "../../common/labelMgt.vue";
import Api from "@/api/devops/frontTemplate";
import commonApi from "@/api/devops/common";
export default {
  components: { addModal, lableMgt },
  data() {
    return {
      form: {
        name: "",
        label: "",
        version: "",
        code: "",
      },
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      lableDrawer: false,
      opertype: "add",
      rowData: {},
      labelList: [],
      addDrawer: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let params = {
        ...this.form,
        current: this.page.current,
        size: this.page.size,
      };
      let res = await Api.getFront(params);
      this.tableData = res.data.records;
      this.page.total = res.data.total;
      this.getLabel();
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    query() {
      this.getData();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await Api.deFront(data.id);
        this.$message.success("操作成功");
        this.getData();
      });
    },
    handleEvent(type, row) {
      this.opertype = type;
      switch (type) {
        case "add":
          this.rowData = {};
          this.addDrawer = true;
          break;
        case "edit":
          this.rowData = _.cloneDeep(row);
          this.addDrawer = true;
          break;
        case "delete":
          this.handleDelete(row);
          break;
      }
    },
    addModalClose() {
      this.addDrawer = false;
    },
  },
};
</script>
<style scoped>
.el-table {
  width: 99.9% !important;
}
</style>
